.node-start {
    width: 63px;
    height: 63px;
    background: var(--output);
    box-shadow: var(--box-shadow);
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    color: #FFFFFF;
}
.node-start.active {
    width: 63px;
    height: 63px;
    background: #162D32;
    box-shadow: var(--box-shadow);
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    color: #FFFFFF;
}
.node-start .edit-node {
    position: absolute;
    right: 0;
    left: 0;
    top: -25px;
    margin-left: auto;
    margin-right: auto;
}